import React, { useEffect, useState } from 'react'
import SearchForm from '../components/SearchForm'
import { Collapse } from 'react-bootstrap'
import '../styles/SearchPage.css'
import SearchResults from '../components/SearchResults';
import { TrialProvider } from '../context/TrialContext';
import { useParams } from 'react-router-dom';
import { search, searchById } from '../services/TrialService';
import { handleResponse } from '../helpers/ResponseHelper';
import { defaultSearchValues } from '../helpers/SearchHelper';


function Search() {

  const [isOpen = true, setIsOpen] = useState(true);

  const [trials, setTrials] = useState();
  const [{ id }] = useState(useParams());
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    const fetchTrials = async () => {
      if (id && !searched) {
        await searchById(id).then(handleResponse).then(trials => {setTrials(trials)});
        setSearched(true);
      } else 
      {
        await search(defaultSearchValues(), "Search", false).then(handleResponse).then(trials => {setTrials(trials)});
        setSearched(true);
      }
    }
    fetchTrials();
  }, [id, searched]);

  return (
    <TrialProvider>
      <div className="container-fluid pt-2 mt-5">
        <div className="row justify-content-md-center">
          <div className="col-xs-12 col-md-9 col-lg-7">
            <div className="card">
              <div className="card-header font-weight-bold d-flex justify-content-between align-items-center">
                Trial Search
                <div className='justify-content-right'>
                  <button className={isOpen ? "btn btn-outline-secondary bi-arrow-bar-up" : "btn btn-outline-secondary bi-arrow-bar-down"} onClick={() => { setIsOpen(!isOpen) }}>
                  </button>
                </div>
              </div>
              <Collapse in={isOpen}>
                <div className="card-body search-card"><SearchForm /></div>
              </Collapse>
            </div>
          </div>
        </div>
        <div>
          <div className="col-md-12">
            {searched && <SearchResults trials={trials} />}
          </div>
        </div>
      </div>
    </TrialProvider>
  )
}

export default Search