import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleResponse } from '../helpers/ResponseHelper';
import { getTrialCount } from '../services/TrialService';
import '../styles/Infographics.css'


function Infographics() {
    const history = useNavigate();
    const [trialCount, setTrialCount] = useState(0); 
    const searchLink = () => { 
        history("/search")
    };

    useEffect(() => {
        getTrialCount().then(handleResponse).then(count => {setTrialCount(count)});
    },[]);
    
    return (
        <div className='pb-5'><h2 className="text-center infographics pb-4">Medulloblastoma.org is currently tracking {trialCount} trials</h2>
            <div className='d-flex justify-content-center trialbtn'>
                <button type="button" className="btn btn-primary btn-lg btn-block" onClick={searchLink}>Search for trials</button>
            </div>
        </div>

    )
}

export default Infographics