import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Collapse, Container, Form, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import { handleResponse } from '../helpers/ResponseHelper';
import { forgotPassword, forgotPasswordReset, validateForgotPassword } from '../services/UserService';

function ForgotPasswordPage() {

	const initialFormData = Object.freeze({
		email: ""
	});
	const initialResetData = Object.freeze({
		newPassword: "",
		confirmPassword: ""
	});

	const [formData, setFormData] = useState(initialFormData);
	const [resetForm, setResetForm] = useState(initialResetData);
	const [error, setError] = useState("");
	const [{ id }] = useState(useParams());
	const [validated, setValidated] = useState(false);
	const history = useNavigate();

	useEffect(() => {
		const validateForgotPass = async () => {
			if (id && !validated) {
				const valid = await validateForgotPassword(id);
				if(valid === true) setValidated(valid);
			}
		}
		validateForgotPass();
	}, [id, validated]);

	const handleChange = (event) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value.trim()
		});
	}
	const handleResetChange = (event) => {
		setResetForm({
			...resetForm,
			[event.target.name]: event.target.value.trim()
		});
	}

	const handleSubmit = async event => {
		event.preventDefault();
		forgotPassword(formData.email);
		setError("Password recovery email has been sent, please allow at least 5 minutes for it to be delivered");
	}

	const handleReset = async event => {
		event.preventDefault();
		if(resetForm.newPassword !== resetForm.confirmPassword){
			setError("Please make sure both passwords match");
			return;
		}
		if(resetForm.newPassword.length < 8){
			setError("Passwords must be at least 8 characters long");
			return;
		}
		const res = await forgotPasswordReset(resetForm.newPassword, id);
		if(res === true){
			setError("Password reset, please login with new password");
			await new Promise(r => setTimeout(r, 1000));
			history("/login");
			return;
		}
	}

	return (
		<div>
			<Container>
				<Row className='justify-content-center pt-5'>
					<Col xs={11} md={9} lg={6} className="text-center">
						{!validated ?
							<div>
								<Form onSubmit={handleSubmit}>
									<Form.Group className="m-2">
										<Form.Label>Email</Form.Label>
										<Form.Control type="email" name="email" onChange={handleChange}></Form.Control>
									</Form.Group>
									<Button variant="primary" type="submit" className='m-2'>
										Recover Password
									</Button>
									<Collapse in={error.length > 0}>
										<p className='h6 text-danger'>{error}</p>
									</Collapse>
								</Form>
							</div>
							:
							<div>
								<Form onSubmit={handleReset}>
									<Form.Group className="m-2">
										<Form.Label>New Password</Form.Label>
										<Form.Control name='newPassword' type='password' onChange={handleResetChange}></Form.Control>
									</Form.Group>
									<Form.Group className="m-2">
										<Form.Label>Confirm Password</Form.Label>
										<Form.Control name='confirmPassword' type='password' onChange={handleResetChange}></Form.Control>
									</Form.Group>
									<Button variant="primary" type="submit" className='m-2'>
										Reset Password
									</Button>
									<Collapse in={error.length > 0}>
										<p className='h6 text-danger'>{error}</p>
									</Collapse>
								</Form>
							</div>
						}
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default ForgotPasswordPage