import Dashboard from './pages/DashboardPage';
import Search from './pages/SearchPage';
import LoginPage from './pages/LoginPage';
import { Routes, Route, Link, Outlet, Navigate, useNavigate } from 'react-router-dom';
import { NavDropdown, Nav, Navbar, Container, Button } from 'react-bootstrap'
import './styles/App.css'
import { executelogout, userCache, userCacheId, userCacheName } from './helpers/UserHelper';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import Profile from './pages/ProfilePage';


function App() {

  const loggedIn = userCache() ? true : false;
  const navigate = useNavigate();
  
  const profilePage = () => {
    navigate("/profile");
  }
  return (
    <div className="App">
      <div>
        <Navbar className='color-nav' variant="light" expand="lg">
          <Container>
            <Navbar.Brand href='/'><img src='/logoL.png' className='logo' alt="" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className='justify-content-end'>
              <Nav className='me-right'>
                <Link className='nav-link' to="/"><i className="fas fa-home"></i> Dashboard</Link>
                <Link className='nav-link' to="/search"><i className="fas fa-search"></i> Trial Search</Link>
                <a className='nav-link' href="https://dipg.org/contact" target="_blank"><i className='fas fa-envelope'></i> Contact</a>
                {loggedIn ?
                  <NavDropdown title={loggedIn ? userCacheName() : "Login"} id="basic-nav-dropdown" placement='end'>
                    <Button className='dropdown-item' type='buttons' onClick={profilePage}>Profile</Button>
                    <Button className='dropdown-item' type="button" onClick={() => executelogout(userCacheId())}>Logout</Button>
                  </NavDropdown>
                  :
                  <Link className='nav-link' to="/login">Login</Link>}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div>
        <Routes>
          <Route path="/" element={loggedIn ? <Dashboard /> : <Navigate to="/login" />}></Route>
          <Route path="/search" element={loggedIn ? <Search /> : <Navigate to="/login" />}></Route>
          <Route path="/search/:id" element={<Search />}></Route>
          <Route path="/login" element={loggedIn ? <Navigate to="/" /> : <LoginPage />}></Route>
          <Route path='/forgot-password' element={loggedIn ? <Dashboard /> : <ForgotPasswordPage />}></Route>
          <Route path='/forgot-password/:id' element={loggedIn ? <Dashboard /> : <ForgotPasswordPage />}></Route>
          <Route path='/profile' element={loggedIn ? <Profile /> : <LoginPage />}></Route>
        </Routes>
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default App;
