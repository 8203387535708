import { userCacheId } from "../helpers/UserHelper";
import { handleResponse } from "../helpers/ResponseHelper"
import configData from "../config.json"
import { authFetch } from "./TokenService";


const api = configData.SERVER_URL+"authManagement/";
const siteId = configData.SITEID;

export async function login(form) {
    const url = api+"Login";
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ...form,
            siteId: siteId
        })
    };
    return fetch(url, requestOptions)
    .then(handleResponse)
    .then(user => {
        if(user.accessToken) {
            localStorage.setItem('user', JSON.stringify(user));
        }
        return user;
    })
    .catch(e => {
        return e;
    });
};
export async function register(form) {
    const url = api+"Register";
    const requestOption = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ...form,
            siteId: siteId
        })
    };

    return fetch(url, requestOption)
    .then(handleResponse)
    .then(user => {
        if(user.accessToken) {
            localStorage.setItem('user', JSON.stringify(user));
        }
        return user;
    })
    .catch(e => {
        return e;
    });
};
export async function logout() {
    const url = api+"Logout";
    const userId = parseInt(userCacheId());
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userId: userId
        })
    };

    return authFetch(url, requestOptions)
    .then(handleResponse)
    .catch(e => {
        return e;
    });
};

export async function forgotPassword(email) {
    const url = api+"ForgotPassword";
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            email: email,
            siteId: siteId
        })
    };

    return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(e => {
        return e;
    });
};

export async function validateForgotPassword(id) {
    const url = api+"ValidateForgotPassword";
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            uuid: id,
            siteId: siteId
        })
    };

    return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(e => {
        return e;
    });
};
export async function forgotPasswordReset(password, id) {
    const url = api+"ForgotPasswordReset";
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            uuid: id,
            password: password,
            siteId: siteId
        })
    };

    return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(e => {
        return e;
    });
};

export async function validatePassword(password) {
    const url = api+"ValidatePassword";
    const userId = parseInt(userCacheId());
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userId: userId,
            password: password
        })
    };

    return authFetch(url, requestOptions)
    .then(handleResponse)
    .catch(e => {
        return e;
    });
}

export async function getProfile() {
    const url = api+"GetProfile";
    const userId = parseInt(userCacheId());
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({userId:userId})
    };

    return authFetch(url, requestOptions)
    .then(handleResponse)
    .catch(e => {
        return e;
    });
}

export async function updateProfile(profileForm) {
    const url = api+"UpdateProfile";
    const userId = parseInt(userCacheId());
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userId: userId,
            siteId: siteId,
            ...profileForm
        })
    };

    return authFetch(url, requestOptions)
    .then(handleResponse)
    .catch(e => {
        return e;
    });
}
