import React, { useContext, useEffect, useState } from 'react'
import { TrialContext, TrialContextDispatch } from '../context/TrialContext'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import '../styles/SearchPage.css'
import '../styles/Loading.css'
import { convertDate } from '../helpers/DateTimeHelper';
import { favoriteTrial, unfavoriteTrial } from '../services/TrialService';
import { handleResponse } from '../helpers/ResponseHelper';
import { favoritesCache } from '../helpers/UserHelper';



function SearchResults(props) {
    const trials = useContext(TrialContext);
    const setTrials = useContext(TrialContextDispatch);
    const [favorites, setFavorites] = useState([]);
    const [sorted, setSorted] = useState(false);
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (!trials && !init) {
            setInit(true);
            setTrials(props.trials);
        }
        setFavorites(JSON.parse(favoritesCache()));
    }, [props.trials, setTrials, trials, init]);

    const isFavorite = (trial) => {
        return favorites.find(({ trialId }) => trialId === trial.trialId) != null;
    }
    const handleSave = async (trial) => {
        await favoriteTrial(trial.trialId).then(handleResponse);
        setFavorites(favorites => [...favorites, trial]);
    }
    const handleRemove = async (trial) => {
        await unfavoriteTrial(trial.trialId).then(handleResponse);
        setFavorites(favorites.filter(function (fav) { return fav.trialId !== trial.trialId }));
    }

    const sort = () => {
        let contextedTrials = trials;
        if (!sorted) {
            setTrials([].concat(contextedTrials.sort((a, b) => a.studyFirstSubmitted > b.studyFirstSubmitted ? 1 : -1)));
            setSorted(true);
        } else {
            setTrials([].concat(contextedTrials.sort((a, b) => b.studyFirstSubmitted > a.studyFirstSubmitted ? 1 : -1)));
            setSorted(false)
        }
    }

    return (
        <div className='pt-3'>
            <hr />
            {trials && trials.length > 0 && trials[0].noResults &&
                <div>
                    <Col xs={11} lg={8} className='mx-auto'>
                        <Row>
                            <div className='card '>
                                <p className='m-4 align-items-center d-flex justify-content-center h5'>
                                    No trials found for your search terms
                                </p>
                            </div>
                        </Row>
                    </Col>
                    <hr></hr>

                </div>}
            {trials && trials.length > 0 && trials[0].noResult &&
                <div>
                    <Col xs={11} lg={8} className='mx-auto'>
                        <Row>
                            <div className='card '>
                                <p className='m-4 align-items-center d-flex justify-content-center h5'>
                                    There were no trials within the distance searched, but results for the entire country are listed below
                                </p>
                            </div>
                        </Row>
                    </Col>
                    <hr></hr>
                </div>}
            {trials && trials.length > 0 && trials[0].hasOwnProperty('source') &&
                <Col xs={12} lg={8} className='mx-auto'>
                    <Row>
                        <Col lg={9} xs={9} className='mx-auto mt-3 mb-2'>
                            {trials[0].isSearched &&
                                <div>
                                    <p className='lead'>{trials.length} results</p>
                                </div>
                            }
                        </Col>
                        <Col lg={3} xs={3} className="mt-2 mb-2">
                            <div className="float-end">
                                <button type="button" className="btn btn-light btn-outline text-secondary pointer" onClick={sort}>Sort <i className="fas fa-sort"></i></button>
                            </div>
                        </Col>
                    </Row>
                    {trials.map((trial, index) =>
                        <div className="card mb-4 p-2 pt-3 pb-3" key={index} >
                            <div className="container-fluid">
                                <Row>
                                    <Col xs={11}>
                                        <a href={trial.url} target="_blank" rel='noreferrer' className='no-style'>
                                            <h5 className="mb-0">{trial.briefTitle}</h5>
                                        </a>
                                        <em><small className="text-muted">{trial.webId} &mdash; Created {convertDate(trial.studyFirstSubmitted)}  &mdash; {trial.registry}</small></em>
                                    </Col>
                                    <Col xs={1}>
                                        {isFavorite(trial) ?
                                            <i className="fas fa-heart float-end text-danger pointer" onClick={() => handleRemove(trial)} />
                                            :
                                            <i className="far fa-heart float-end text-danger pointer" onClick={() => handleSave(trial)} />
                                        }
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={9}>
                                        <i className="fad fa-clinic-medical"></i> &nbsp;{trial.source} {trial.locations > 1 ? <span>(+{trial.locations} others)</span> : <></>} <br />
                                        {trial.distance > 0 ?
                                            <div>{trial.inDistanceLocationsNames && trial.inDistanceLocationsNames.length > 1 ?
                                                <div><i className="fas fa-location"></i> &nbsp;{trial.locationName}
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 50, hide: 400 }}
                                                        overlay={<Tooltip >{trial.inDistanceLocationsNames.map((name, index) =>
                                                            <li key={index}>{name}</li>)}
                                                        </Tooltip>}
                                                    >
                                                        <span>&nbsp;(<u>+{trial.inDistanceLocationsNames.length} others</u>)</span>
                                                    </OverlayTrigger></div>
                                                :
                                                <div><i className="fas fa-location"></i> &nbsp;{trial.locationName} </div>}

                                            </div>
                                            : <></>}
                                    </Col>
                                    <Col xs={3}>
                                        {trial.distance > 0 ? <br /> : <></>}
                                        <div className="float-end align-text-bottom">
                                            {trial.distance > 0 ? <span><span className="badge bg-secondary text-uppercase p-1">{trial.distance} {trial.measure}</span>&nbsp;</span> : <></>}
                                            <span className="badge bg-info text-uppercase ">{trial.overallStatus}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )}
                </Col>
            }
            {trials ? <></> : <div className="d-flex justify-content-center"> <div className="loader "></div></div>}
        </div>
    )
}

export default SearchResults