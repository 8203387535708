import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import { useState } from "react";
import LoginForm from "../components/LoginForm"
import RegisterForm from "../components/RegisterForm";

function LoginPage() {
  const [key, setKey] = useState('login');

    return (
    
        <Container>
          <Row className="justify-content-center pt-5">
                <Col xs={12} sm={12} md={8} lg={6}>
                    <div className="card pb-5">
                        <Tabs fill justify activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 nav-pills">
                            <Tab eventKey="login" title="Login">
                              <LoginForm />
                            </Tab>
                            <Tab eventKey="register" title="Register">
                              <RegisterForm />
                            </Tab>
                        </Tabs>
                    </div>
                </Col>
          </Row>
        </Container>

  )
}

export default LoginPage