import React, { useState } from 'react'
import { Col, Row, Form, Modal, Button } from 'react-bootstrap'
import { convertDate } from '../helpers/DateTimeHelper';
import { handleResponse } from '../helpers/ResponseHelper';
import { cacheFavorites, favoritesCache } from '../helpers/UserHelper';
import { getFavoriteTrial, unfavoriteTrial, updateFavoriteTrial } from '../services/TrialService';


function FavoriteTrials(props) {
    const [trials, setTrials] = useState(props.trials);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState();

    const handleRemove = async (trial) => {
        await unfavoriteTrial(trial.trialId).then(handleResponse);
        setTrials(trials.filter(function (fav) { return fav.trialId !== trial.trialId }));
        cacheFavorites(JSON.parse(favoritesCache()).filter(function(fav) { return fav.trialId !== trial.trialId }));
    }

    const handleCheck = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.checked
        });
    }
    
    const modifyFavorite = async (trial) => {
        const results = await getFavoriteTrial(trial.trialId).then(handleResponse);
        setFormData({...results,trialId:trial.trialId});
        setShowModal(true);
    }

    const handleSubmit = async (trial) => { 
        const result = await updateFavoriteTrial(formData);
        if (result.ok) {
            await new Promise(r => setTimeout(r, 600));
            setShowModal(false);
          }
    }

    return (
        <div>
            {trials && trials.length > 0 ?
                <div>
                    <Col xs={12} className='mx-auto'>
                        {trials.map((trial, index) =>
                            <div className="card mb-4 p-2 pt-3 pb-3" key={index} >
                                <div className="container-fluid">
                                    <Row>
                                        <Col xs={11}>
                                            <a href={trial.url} target="_blank" rel='noreferrer' className='no-style'>
                                                <h5 className="mb-0">{trial.briefTitle}</h5>
                                            </a>
                                            <em><small className="text-muted">{trial.webId} &mdash; Created {convertDate(trial.studyFirstSubmitted)}</small></em>
                                        </Col>
                                        <Col xs={1}>
                                            <div className="mb-1"><i className="fas fa-pen float-end pointer" onClick={() => modifyFavorite(trial)}></i></div><br />
                                            <div><i className="fas fa-trash float-end pointer" onClick={() => handleRemove(trial)}></i></div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={9}>
                                            <i className="fad fa-clinic-medical"></i> &nbsp;{trial.source} {trial.locations > 1 ? <span>(+{trial.locations} others)</span> : <></>}
                                        </Col>
                                        <Col xs={3}>
                                            <div className="float-end"><span className="badge bg-info text-uppercase">{trial.overallStatus}</span></div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}
                    </Col>
                    {showModal && <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Would you like notifications
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Check
                                    label="Notify on status changes"
                                    type="checkbox"
                                    name="statusNotify"
                                    onChange={handleCheck}
                                    checked={formData.statusNotify}
                                />
                                <Form.Check
                                    label="Notify on location changes"
                                    type="checkbox"
                                    name="locationNotify"
                                    onChange={handleCheck}
                                    checked={formData.locationNotify}
                                />
                                <Form.Check
                                    label="Notify on study type changes"
                                    type="checkbox"
                                    name="typeNotify"
                                    onChange={handleCheck}
                                    checked={formData.typeNotify}
                                />
                                <Form.Check
                                    label="Notify on start date changes"
                                    type="checkbox"
                                    name="dateNotify"
                                    onChange={handleCheck}
                                    checked={formData.dateNotify}
                                />
                                <Form.Check
                                    label="Notify on elgibility changes"
                                    type="checkbox"
                                    name="eliNotify"
                                    onChange={handleCheck}
                                    checked={formData.eliNotify}
                                />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleSubmit}>Save</Button>
                        </Modal.Footer>
                    </Modal>}
                </div>
            :
            <h1>No favorites</h1>}
        </div>
    )
}

export default FavoriteTrials