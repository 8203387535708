
import { executelogout, userCacheId } from './UserHelper';

export function handleResponse(res) {
    
    return res.text().then(text => {
        let data = ""
        try {
            data = text && JSON.parse(text);
        } catch (e) {
            data = text;
        }
        if (!res.ok) {
            if(res.status === 401) {
                executelogout(userCacheId);
                return;
            }
            const error = data.error || res.statusText || data.title;
            return Promise.reject(error);
        }
        return data;
    });
}