import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { handleResponse } from '../helpers/ResponseHelper';
import { removeSavedSearch } from '../services/TrialService';

function SavedSearches(props) {

  const [searches, setSearches] = useState(props.searches);

  const removeSearch = async (searchId) => {
    const res = await removeSavedSearch(searchId).then(handleResponse);
    console.log(res);
    if (res) {
      setSearches(searches.filter(function (rem) { return rem.searchId !== searchId }));
    } else {
      console.log("Error")
    }
  }

  return (
    <div>
      {searches && searches.length > 0 ?

        <div>
            <Col xs={12} className='mx-auto'>
                {searches.map((search, index) =>
                    <div className='card mb-1 p-3 pb-1' key={index}>
                        <Row>
                            <Col xs={10}>
                                <p><Link to={`/search/${search.searchId}`}>{search.searchName}</Link> &nbsp;<span className="badge bg-info text-uppercase">{search.updates}</span></p>
                            </Col>
                            <Col xs={2}>
                                <i className="fas fa-trash float-end pointer pt-1" onClick={() => removeSearch(search.searchId)}></i>
                            </Col>
                        </Row>
                    </div>
                )}
            </Col>
        </div>
        : <p>No searches</p>}
    </div>

  )
}

export default SavedSearches