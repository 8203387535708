import { useState, createContext } from "react";

const TrialContext = createContext();
const TrialContextDispatch = createContext();

function TrialProvider({ children }) {
    const [trials, setTrials] = useState();
    return <TrialContext.Provider value={trials}>
        <TrialContextDispatch.Provider value={setTrials}>
            {children}
        </TrialContextDispatch.Provider>
    </TrialContext.Provider>
}

export { TrialProvider, TrialContext, TrialContextDispatch }