import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { search } from '../services/TrialService';

const initialFormData = Object.freeze({
  name: "",
  newTrialNotify:false,
  statusNotify: false,
  locationNotify: false,
  typeNotify: false,
  dateNotify: false,
  eliNotify: false,
});

function SaveSearchModal(props) {

  const [formData, setFormData] = useState(initialFormData);
  const searchData = props.data;
  
  
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.trim()
    });
  };
  const handleCheck = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const savedSearchData = {
      ...searchData,
      ...formData
    }
    const savedSearchRes = await search(savedSearchData, "SaveSearch");
    if (savedSearchRes.ok) {
      await new Promise(r => setTimeout(r, 600));
      props.onHide();
    } else {
      //todo error handling
      console.log("Error");
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Save Search
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Group className="m-3">
              <Form.Label>Name the saved search</Form.Label>
              <Form.Control type="text" onChange={handleChange} name="name"></Form.Control>
            </Form.Group>
          <Form.Check
            label="Notify on new trials added"
            type="checkbox"
            name="newTrialNotify"
            onChange={handleCheck}
          />
          <Form.Check
            label="Notify on status changes"
            type="checkbox"
            name="statusNotify"
            onChange={handleCheck}
          />
          <Form.Check
            label="Notify on location changes"
            type="checkbox"
            name="locationNotify"
            onChange={handleCheck}
          />
          <Form.Check
            label="Notify on study type changes"
            type="checkbox"
            name="typeNotify"
            onChange={handleCheck}
          />
          <Form.Check
            label="Notify on start date changes"
            type="checkbox"
            name="dateNotify"
            onChange={handleCheck}
          />
          <Form.Check
            label="Notify on elgibility changes"
            type="checkbox"
            name="eliNotify"
            onChange={handleCheck}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default SaveSearchModal