import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { getCountries, getCountryName } from '../helpers/CountryListHelper';
import { cacheUser, uncacheUser, userCache } from '../helpers/UserHelper';
import { getProfile, updateProfile, validatePassword } from '../services/UserService';

function Profile() {

  const user = JSON.parse(userCache());

  const initialFormData = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    password: "",
    confirmPassword: "",
    oldPassword: "",
    trialUpdate: false,
    newTrial: false,
    zipCode: user.zipCode,
    country: user.country,
  };

  const [isEdit, setIsEdit] = useState(false);
  const [isChangePass, setIsChangePass] = useState(false);
  const userCountryName = getCountryName(user.country);
  const countryArr = getCountries();
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getProfile();
      setFormData({
        ...formData,
        newTrial: profile.isOptInNew,
        trialUpdate: profile.isOptInUpdates
      })

    }
    fetchProfile();
  }, []);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.trim()
    });
  };

  const handleCheck = (event) => {
    console.log("Handling check")
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    console.log(formData)
    if (isChangePass) {
      if (formData.password !== formData.confirmPassword) {
        setError("Passwords don't match");
        return;
      }
      if (formData.password.length < 8) {
        setError("Passwords must be at least 8 characters long");
        return;
      }
      const passwordConfirm = await validatePassword(formData.oldPassword);
      if (!passwordConfirm) {
        setError("Old password not valid");
        return;
      }
      else {
        setMessage("Updated!");
        return;
      };
    }
    else if (isEdit) {
      const res = await updateProfile(formData);
      if (res) {
        uncacheUser();
        const newUser = user;
        newUser.firstName = formData.firstName;
        newUser.lastName = formData.lastName;
        newUser.email = formData.email;
        newUser.zipCode = formData.zipCode;
        newUser.country = formData.country;
        cacheUser(JSON.stringify(newUser));
        setIsEdit(false);
        setMessage("Updated!");
      }
      else {
        setError("Unable to update profile");
      }
    }

  }

  return (
    <div>
      <Container>
        <Row className='justify-content-center'>
          <Col lg={10} xs={11} className='align-self-center mt-3'>
            <div className="card pb-5">
              <p className='h4 mx-auto pt-3'>Profile Page</p>
              <Row className='justify-content-center mt-4'>
                <Col md={4} xs={11}>
                  <label>First Name</label>
                  <input type="text" className='form-control' value={formData.firstName} name="firstName" disabled={!isEdit} onChange={handleChange}></input>
                </Col>
                <Col md={4} xs={11}>
                  <label>Last Name</label>
                  <input type="text" className='form-control' value={formData.lastName} name="lastName" disabled={!isEdit} onChange={handleChange}></input>
                </Col>
              </Row>
              <Row className='justify-content-center mt-3'>
                <Col md={8} xs={11}>
                  <label>Email</label>
                  <input type="email" className='form-control' value={formData.email} name="email" disabled={!isEdit} onChange={handleChange}></input>
                </Col>
              </Row>
              <Row className='justify-content-center mt-3'>
                <Col md={3} xs={11}>
                  <label>Postal Code</label>
                  <input type="text" className='form-control' value={formData.zipCode} name="zipCode" disabled={!isEdit} onChange={handleChange}></input>
                </Col>
                <Col md={5} xs={11}>
                  <label>Country</label>
                  <select className='form-control' disabled={!isEdit} onChange={handleChange} name="country">
                    {<option value={user.country}>{userCountryName}</option>}
                    {countryArr.map(({ code, name }) => (
                      <option key={name} value={code}>{name}</option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row className="justify-content-center pt-4">
                <Col xs={11} md={4}>
                  <input
                    className='form-check-input'
                    type="checkbox"
                    name="newTrial"
                    checked={formData.newTrial}
                    disabled={!isEdit}
                    onChange={handleCheck}
                  ></input><span> Recieve notifications for newly added trials</span>
                </Col>
               <Col xs={11} md={4}>
                  <input
                    className='form-check-input'
                    label="Recieve New Trial Notifications"
                    type="checkbox"
                    name="trialUpdate"
                    checked={formData.trialUpdate}
                    disabled={!isEdit}
                    onChange={handleCheck}
                  ></input><span> Recieve notifications when trials are updated</span>
                </Col>
              </Row>
              {isChangePass || isEdit ?
                <div>
                  <div>
                    {isChangePass ? <div>
                      <Row className='justify-content-center mt-3'>
                        <Col md={4} xs={11}>
                          <label>New Password</label>
                          <input type="password" className='form-control' name='password' onChange={handleChange}></input>
                        </Col>
                        <Col md={4} xs={11}><label>Confirm Password</label>
                          <input type="password" className='form-control' name='confirmPassword' onChange={handleChange}></input>
                        </Col>
                      </Row>

                      <Row className='justify-content-center mt-3'>
                        <Col md={4} xs={11} className='align-self-center'>
                          <label>Enter old password to confirm</label>
                          <input type="password" className='form-control' name='oldPassword' onChange={handleChange}></input>
                        </Col>
                      </Row>

                    </div>
                      :
                      <></>}
                  </div>

                  <Row className='justify-content-center mt-3'>
                    <Col md={4} xs={11} className='align-self-center'><p> {error} </p></Col>
                  </Row>
                  <Row className='justify-content-center mt-3'>
                    <Col md={2} xs={11} className='pt-2'>
                      <Button className='btn' onClick={handleSubmit}>Save</Button>
                    </Col>
                    <Col md={2} xs={11} className='pt-2'>
                      <Button className='btn-danger' onClick={() => { setIsEdit(false); setIsChangePass(false); }}>Cancel</Button>
                    </Col>
                  </Row>
                </div>
                :
                <div>
                  <Row className='justify-content-center mt-4'>
                    <Col md={3} xs={11} className="mt-2">
                      <Button onClick={() => { setIsEdit(!isEdit) }}>Edit Profile</Button>
                    </Col>
                    <Col md={3} xs={11} className="mt-2">
                      <Button onClick={() => { setIsChangePass(!isChangePass) }}>Change Password</Button>
                    </Col>
                  </Row>
                  
                </div>
              }
              <Row className='justify-content-center mt-4'>{message}</Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Profile