import { authFetch } from "./TokenService";
import configData from "../config.json";
import { userCacheId } from "../helpers/UserHelper";

const api = configData.SERVER_URL+"trialManagement/";
const siteId = configData.SITEID;

export async function search(search, url="Search"){
    const userId = userCacheId();
    url = api+url;
    const body = {
        ...search,
        userId: userId,
        siteId: siteId
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return authFetch(url, requestOptions);
}

export async function saveSearch(search){
    const url = api+"SaveSearch";
    const userId = userCacheId();
    const body = {
        ...search,
        userId: userId,
        siteId: siteId
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return authFetch(url, requestOptions);
}

export async function removeSavedSearch(searchId){
    const url = api+"RemoveSavedSearch";
    const userId = userCacheId();
    
    const body = {
        searchId: searchId,
        userId: userId,
        siteId: siteId
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return authFetch(url, requestOptions);
}

export async function saveSearchList(){
    const url = api+"SaveSearchList";
    const userId = userCacheId();
    const body = {
        userId: userId
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return authFetch(url, requestOptions);
}

export async function searchById(searchId){
    const url = api+"SearchById";
    const body = {
        searchId: searchId
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return authFetch(url, requestOptions);
}

export async function favoriteTrial(trialId){
    const url = api+"FavoriteTrial";
    const userId = userCacheId();
    const body = {
        trialID: trialId,
        userID: userId
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return authFetch(url, requestOptions);
}

export async function unfavoriteTrial(trialId){
    const url = api+"UnfavoriteTrial";
    const userId = userCacheId();
    const body = {
        trialID: trialId,
        userID: userId
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return authFetch(url, requestOptions);
}

export async function getFavoriteTrials(){
    const url = api+"GetFavoriteTrials";
    const userId = userCacheId();
    const body = {
        userID: userId
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return authFetch(url, requestOptions);
}

export async function getFavoriteTrial(trialId){
    const url = api+"GetFavoriteTrial";
    const userId = userCacheId();
    const body = {
        trialId: trialId,
        userID: userId,
        siteId: siteId
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return authFetch(url, requestOptions);
}

export async function updateFavoriteTrial(trialData) {
        const url = api+"UpdateFavoriteTrial";
        const userId = userCacheId();
        const body = {
            ...trialData,
            userID: userId
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        };
    
        return authFetch(url, requestOptions);
}

export async function getTrialCount() {
    const url = new URL(api+`GetTrialCount`);
    url.searchParams.append("siteId",siteId);
    const requestOptions = {
        method: 'GET'
    };

    return fetch(url, requestOptions);
}