import Infographics from "../components/Infographics"
import FavoriteTrials from "../components/FavoriteTrials"
import SavedSearches from "../components/SavedSearches"
import { getFavoriteTrials, saveSearchList } from "../services/TrialService"
import { handleResponse } from "../helpers/ResponseHelper"
import { useEffect, useState } from "react"
import { cacheFavorites } from "../helpers/UserHelper"
import "../styles/Loading.css"


function Dashboard() {

  const [searches, setSearches] = useState();
  const [favorites, setFavorites] = useState();
  const [loadingFavorites, setLoadingFavorites] = useState(true);
  const [loadingSearches, setLoadingSearches] = useState(true);

  useEffect(() => {
    async function loadSavedSearch() {
      const searches = await saveSearchList().then(handleResponse);
      setSearches(searches);
      setLoadingSearches(false);
    }
    loadSavedSearch();
    async function loadFavoriteTrials() {
      const favorites = await getFavoriteTrials().then(handleResponse);
      setFavorites(favorites);
      cacheFavorites(favorites);
      setLoadingFavorites(false);
    }
    loadFavoriteTrials();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
        </div>
        <div className="col-md-6">
          <Infographics />
        </div>
        <div className="col-md-3">
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9">
          <div><h5>Favorite Trials</h5>
            {loadingFavorites ? <div className="d-flex justify-content-center"> <div className="loader "></div></div>
              :
              <FavoriteTrials trials={favorites} />}
          </div>
        </div>
        <div className="col-lg-3">
          <div><h5>Saved Searches</h5>
            {loadingSearches ? <div className="d-flex justify-content-center"> <div className="loader "></div></div>
              :
              <SavedSearches searches={searches} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard