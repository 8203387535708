import configData from "../config.json"
import { asyncUserToken, refreshUserCacheToken, userCacheId } from "../helpers/UserHelper"

export async function authFetch(url, requestOptions) {
    const tokens = await asyncUserToken();
    const options = requestOptions || {};
    options.headers = requestOptions.headers || {};
    options.headers['Authorization'] = 'Bearer ' + tokens.accessToken;
    const res = await fetch(url, options);
    if (res.ok) {
        return res;
    }
    
    if (res.status === 401) {
        const refreshRes = await refresh(tokens.refreshToken);
        if (!refreshRes.ok) {
            return res;
        }

        const jsonRefreshRes = await refreshRes.json();
        refreshUserCacheToken(jsonRefreshRes.accessToken, jsonRefreshRes.refreshToken);
        return await authFetch(url, requestOptions);

    } else return res;
}

async function refresh(refreshToken) {
    const userId = userCacheId();
    return fetch(configData.SERVER_URL+"authManagement/RefreshToken", {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({userId:userId, refreshToken:refreshToken})
    });
}