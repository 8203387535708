import { useState } from "react";
import { Col, Form, Row, Button, Collapse } from "react-bootstrap"
import { Link } from "react-router-dom";
import { login } from "../services/UserService";

const initialFormData = Object.freeze({
    email: "",
    password: ""
});

function LoginForm() {

    const [formData, setFormData] = useState(initialFormData);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value.trim()
        });
    }
    const handleSubmit = async event => {
        event.preventDefault();
        const loginRes = await login(formData);
        setEmailError("");
        setPasswordError("");
        setFormData({
            email:formData.email,
            password: ""
        })
        if (loginRes.accessToken) {
            window.location.reload(false);
        } else if (loginRes === "Email not found") {
            setEmailError(loginRes);
        } else if(loginRes === "Invalid Password") {
            setPasswordError(loginRes);
        } else setPasswordError("Please check your username and password and try again");
        return;
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Row className="pt-2 justify-content-center">
                <Form.Group as={Col} xs={10} md={10} className="m-2">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" onChange={handleChange}></Form.Control>
                </Form.Group>
                <Collapse in={emailError.length > 0}>
                    <div className="text-center">
                        <p className='h6 text-danger'>{emailError}</p>
                    </div>
                </Collapse>
            </Row>
            <Row className="justify-content-center">
                <Form.Group as={Col} xs={10} md={10} className="m-2">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" name="password" onChange={handleChange} value={formData.password}></Form.Control>
                </Form.Group>
                <Collapse in={passwordError.length > 0}>
                    <div className="text-center">
                        <p className='h6 text-danger'>{passwordError}</p>
                    </div>
                </Collapse>
            </Row>
            <Row className="pt-4">
                <Col className="text-center">
                    <Button variant="primary" onClick={handleSubmit}>
                        Login
                    </Button>
                </Col>
            </Row>
            <Row className="pt-4">
            <Col className="text-center">
                <small><Link to={`/forgot-password`}> Forgot password?</Link></small>
                </Col>
            </Row>
        </Form>
    );
}

export default LoginForm